import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'
import SVG from 'react-inlinesvg'

// Assets
import Rocket from '../../assets/images/etapes/rocket.svg'
import Confetti from '../../assets/images/etapes/confetti.svg'

const Container = styled.div`
  background-color: #fef8f7;
  margin: 5rem auto 0 auto;
  overflow: hidden;
  padding: 1rem;

  @media ${device.tablet} {
    margin-top: 0;
    display: flex;
    justify-content: center;
  }

  @media ${device.desktop} {
    padding: 2rem 1rem;
  }
`

const InnerWrapper = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-content: space-around;
    width: 800px;
  }

  @media ${device.desktop} {
    width: 1040px;
  }
`

const LeftBlock = styled.div`
  margin: 0 1rem;
  text-align: center;
  max-width: 100vw;

  #rocket {
    max-width: 326px;
    height: auto;
  }

  @media ${device.desktop} {
    #rocket {
      max-width: 100%;
    }
  }
`

const RightBlock = styled.div`
  #confetti {
    display: none;
  }

  @media ${device.tablet} {
    margin-right: 2rem;
    position: relative;

    #confetti {
      display: block;
      position: absolute;
      right: -30px;
      top: 0;
    }
  }
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin: 3rem auto 1rem auto;
  max-width: 260px;
  text-align: center;

  @media ${device.tablet} {
    margin-left: 0;
    max-width: 240px;
    text-align: left;
  }

  @media ${device.desktop} {
    font-size: 28px;
    line-height: 42px;
    max-width: 300px;
  }
`

const Text = styled.div`
  color: ${colours.grey};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 2rem auto 3rem auto;
  max-width: 260px;
  text-align: center;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.desktop} {
    font-size: 21px;
    line-height: 29px;
    margin-top: 0;
    max-width: 340px;
  }
`

function Banner() {
  return (
    <Container>
      <InnerWrapper>
        <LeftBlock>
          <SVG src={Rocket} id="rocket" />
        </LeftBlock>
        <RightBlock>
          <Title>Et voilà votre vente est bouclée !</Title>
          <Text>Recevez vos fonds et démarrez vos nouveaux projets.</Text>
          <SVG src={Confetti} id="confetti" />
        </RightBlock>
      </InnerWrapper>
    </Container>
  )
}

export default Banner
