import * as React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SEOimg from '../assets/images/etapes/hero.png'
import Block1 from '../components/etapes/Block1'
import Banner from '../components/etapes/Banner'

const EtapesPage = () => {
  return (
    <Layout>
      <SEO
        title={`Les étapes de votre projet de vente`}
        description={`Les étapes de votre projet de vente.`}
        keywords={`étapes projet de vente, projet de vente immobilière, projet de vente allowa`}
        image={SEOimg}
        pathname={`etapes-de-vente`}
      />
      <Block1 />
      <Banner />
    </Layout>
  )
}

export default EtapesPage
