import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'
import SVG from 'react-inlinesvg'

// Assets
import figure1 from '../../assets/images/etapes/etape1.svg'
import figure2 from '../../assets/images/etapes/etape2.svg'
import figure3 from '../../assets/images/etapes/etape3.svg'
import figure4 from '../../assets/images/etapes/etape4.svg'
import figure5 from '../../assets/images/etapes/etape5.svg'
import figure6 from '../../assets/images/etapes/etape6.svg'
import figure7 from '../../assets/images/etapes/etape7.svg'
import figure8 from '../../assets/images/etapes/etape8.svg'
import Down from '../../assets/images/etapes/arrow-down.svg'
import Right from '../../assets/images/etapes/arrow-right.svg'
import ArrowTab from '../../assets/images/etapes/arrow-tablet.svg'
import Arrow1 from '../../assets/images/etapes/arrow1.svg'
import Arrow2 from '../../assets/images/etapes/arrow2.svg'
import Arrow3 from '../../assets/images/etapes/arrow3.svg'
import Arrow5 from '../../assets/images/etapes/arrow4.svg'
import Arrow6 from '../../assets/images/etapes/arrow5.svg'
import Arrow7 from '../../assets/images/etapes/arrow6.svg'

const Container = styled.div`
  margin: 3rem auto 0 auto;
  text-align: center;

  @media ${device.tablet} {
    max-width: 800px;
  }

  @media ${device.desktop} {
    margin: 0.5rem auto;
    max-width: 1140px;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 auto;
  max-width: 260px;

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 38px;
    padding-top: 2rem;
  }

  @media ${device.desktop} {
    font-size: 28px;
    font-weight: 600;
    line-height: 57px;
    max-width: 100%;
  }
`

const Dash = styled.div`
  border: 4px solid ${colours.orange};
  display: block;
  margin: 1.5rem auto 4rem auto;
  width: 60px;
`

const Blocks = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media ${device.desktop} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

const Wrapper = styled.div``

const TitleWrapper = styled.div`
  @media ${device.desktop} {
    display: flex;
    justify-content: start;
  }
`

const Figure = styled(SVG)`
  height: 86px;
  margin-bottom: 1rem;
  margin-left: 18px;
  margin-top: 1rem;
  width: 86px;

  @media ${device.desktop} {
    height: 120px;
    margin-top: 2rem;
    width: 120px;
  }
`

const Number = styled.div`
  background-color: ${colours.allowaBlue};
  border-radius: 100px;
  color: white;
  font-size: 21px;
  font-weight: 700;
  height: 40px;
  margin: 1rem auto;
  position: relative;
  width: 40px;

  span {
    left: 15px;
    position: absolute;
    top: 7.5px;
  }

  #number1 {
    left: 17px;
  }

  #number5,
  #number8 {
    left: 14px;
  }

  #number4,
  #number6 {
    left: 13px;
  }

  @media ${device.desktop} {
    margin: 0;
  }
`

const Subtitle = styled.div`
  color: ${colours.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin: 1.4rem auto 1.5rem auto;
  max-width: 240px;

  @media ${device.desktop} {
    margin: 0.3rem 0 0 1.4rem;
    text-align: left;
  }
`

const Text = styled.div`
  color: ${colours.grey};
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin: 1rem auto;
  max-width: 280px;

  @media ${device.desktop} {
    margin-top: 1.4rem;
    max-width: 360px;
    text-align: left;
  }
`

const OddBlock = styled.div`
  @media ${device.tablet} {
    margin-bottom: 5rem;
    padding-right: 2rem;
    position: relative;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 6rem;

    ${Wrapper} {
      margin-left: 3rem;
    }

    ${Figure} {
      margin-right: 16rem;
    }

    &:nth-of-type(3) {
      margin-bottom: 9rem;
    }

    &:nth-of-type(5) {
      margin-bottom: 11rem;
    }

    &:nth-of-type(7) {
      margin-bottom: 11rem;
      margin-top: 1rem;
    }
  }
`

const EvenBlock = styled.div`
  #figure4,
  #figure6 {
    margin-left: 0;
  }

  @media ${device.tablet} {
    margin-bottom: 6rem;
    padding-left: 2rem;
    position: relative;
  }

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 18.5em;

    ${Wrapper} {
      margin-right: 3rem;
    }

    &:nth-of-type(4) {
      margin-bottom: 2rem;
    }

    &:last-of-type {
      margin-bottom: 4rem;

      ${Subtitle} {
        margin-top: -0.5rem;
      }
    }
  }
`

const ArrowDown = styled(SVG)`
  @media ${device.tablet} {
    display: none;
  }
`

const ArrowRight = styled(SVG)`
  display: none;

  @media ${device.tablet} {
    display: block;
    margin-top: 8.5rem;
  }

  @media ${device.desktop} {
    display: none;
  }
`

const ArrowTablet = styled(SVG)`
  display: none;

  @media ${device.tablet} {
    bottom: -5.5rem;
    display: block;
    position: absolute;
    right: 2rem;
    width: 493px;
    z-index: -1;
  }

  @media ${device.desktop} {
    display: none;
  }
`

const ArrowEtape1 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    position: absolute;
    right: 9rem;
    top: 4rem;
    z-index: -1;
  }
`

const ArrowEtape2 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    left: 14rem;
    position: absolute;
    top: 10rem;
    z-index: -1;
  }
`

const ArrowEtape3 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    left: 11rem;
    position: absolute;
    top: 6.5rem;
    z-index: -1;
  }
`

const ArrowEtape5 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    left: 5rem;
    position: absolute;
    top: 6rem;
    z-index: -1;
  }
`

const ArrowEtape6 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    left: 22rem;
    position: absolute;
    top: 12rem;
    z-index: -1;
  }
`

const ArrowEtape7 = styled(SVG)`
  display: none;

  @media ${device.desktop} {
    display: block;
    left: 3rem;
    position: absolute;
    top: 12rem;
    z-index: -1;
  }
`

function Block1() {
  return (
    <Container>
      <Title>
        Les étapes de votre projet de vente <Dash />
      </Title>
      <Blocks>
        <OddBlock>
          <Figure src={figure1} id="figure1" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number1">1</span>
              </Number>
              <Subtitle>Estimation</Subtitle>
            </TitleWrapper>
            <Text>
              Notre expert local se rend gratuitement et sans engagement sur place pour effectuer
              une valorisation précise du bien à vendre. Nos experts sont spécialement sélectionnés
              pour leurs connaissances du marché.
            </Text>
          </Wrapper>
          <ArrowEtape1 src={Arrow1} />
        </OddBlock>
        <ArrowDown src={Down} />
        <ArrowRight src={Right} />
        <EvenBlock>
          <Figure src={figure2} id="figure2" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number2">2</span>
              </Number>
              <Subtitle>Qualification</Subtitle>
            </TitleWrapper>
            <Text>
              Toutes les informations concernant le bien sont collectées sur une fiche par l’expert
              au moment de l’évaluation afin de donner tous les détails aux potentiels acheteurs. La
              fiche est disponible sur le compte en ligne du vendeur afin d’être enrichie et/ou
              modifiée quand vous le souhaitez (prix, détails...).
            </Text>
          </Wrapper>
          <ArrowTablet src={ArrowTab} />
          <ArrowEtape2 src={Arrow2} />
        </EvenBlock>
        <ArrowDown src={Down} />
        <OddBlock>
          <Figure src={figure3} id="figure3" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number3">3</span>
              </Number>
              <Subtitle>Valorisation</Subtitle>
            </TitleWrapper>
            <Text>
              Les premières impressions comptent ! Un professionnel se déplace à votre domicile à un
              moment qui vous convient, pour prendre des photographies de qualité. Nous vous
              laisserons sélectionner vos photos favorites.
            </Text>
          </Wrapper>
          <ArrowEtape3 src={Arrow3} />
        </OddBlock>
        <ArrowDown src={Down} />
        <ArrowRight src={Right} />
        <EvenBlock>
          <Figure src={figure4} id="figure4" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number4">4</span>
              </Number>
              <Subtitle>Diffusion</Subtitle>
            </TitleWrapper>
            <Text>
              Aujourd'hui, 98% des acquéreurs potentiels cherchent d’abord sur internet. Vendre sans
              l'aide des canaux de communication connus du grand public est presque impossible.
              C'est pour cela que votre annonce sera diffusée sur les principaux sites d'annonces
              immobilières (Leboncoin, Se Loger, Explorimmo...) Nous enverrons également votre
              annonce à notre base de données d'acquéreurs qualifiés.
            </Text>
          </Wrapper>
          <ArrowTablet src={ArrowTab} />
        </EvenBlock>
        <ArrowDown src={Down} />
        <OddBlock>
          <Figure src={figure5} id="figure5" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number5">5</span>
              </Number>
              <Subtitle>Captation</Subtitle>
            </TitleWrapper>
            <Text>
              Nous nous occupons de tout. Nous faisons office de secrétariat téléphonique et
              réceptionnons pour vous les appels, mails et sms. Nous nous assurons de la bonne
              transmission de toutes les informations pertinentes ainsi que de la mise en valeur du
              bien directement auprès du potentiel acheteur. Bien évidement nous qualifions le
              financement des acquéreurs potentiels.
            </Text>
          </Wrapper>
          <ArrowEtape5 src={Arrow5} />
        </OddBlock>
        <ArrowDown src={Down} />
        <ArrowRight src={Right} />
        <EvenBlock>
          <Figure src={figure6} id="figure6" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number6">6</span>
              </Number>
              <Subtitle>Réalisation des visites</Subtitle>
            </TitleWrapper>
            <Text>
              Notre plateforme est en ligne, mais nos services s’opèrent sur le terrain pour un
              meilleur accompagnement. Beaucoup de nouveaux acteurs low-cost 100% en ligne proposent
              leur service sans se déplacer dans votre logement. Nous pensons que c’est une erreur.
              La vente professionnelle d’un logement ne peut se faire 100% à distance. Nous
              déplaçons donc nos agents chez vous pour préciser votre estimation, vous conseiller,
              et réaliser les visites auprès de vos acquéreurs.
            </Text>
          </Wrapper>
          <ArrowTablet src={ArrowTab} />
          <ArrowEtape6 src={Arrow6} />
        </EvenBlock>
        <ArrowDown src={Down} />
        <OddBlock>
          <Figure src={figure7} id="figure7" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number7">7</span>
              </Number>
              <Subtitle>Négociation & Closing</Subtitle>
            </TitleWrapper>
            <Text>
              Votre expert local vous accompagne dans la négociation avec l’acheteur. Le processus
              de vente peut s’avérer compliqué et stressant. Le prix proposé par l'acheteur est-il
              juste ? Quelle contre-proposition formuler ? Comment rédiger le compromis ? Autant de
              questions capitales à prendre très au sérieux au moment de conclure une vente.
            </Text>
          </Wrapper>
          <ArrowEtape7 src={Arrow7} />
        </OddBlock>
        <ArrowDown src={Down} />
        <ArrowRight src={Right} />
        <EvenBlock>
          <Figure src={figure8} id="figure8" />
          <Wrapper>
            <TitleWrapper>
              <Number>
                <span id="number8">8</span>
              </Number>
              <Subtitle>Accompagnement juridique de la Vente</Subtitle>
            </TitleWrapper>
            <Text>
              Nous vous accompagnons tout au long du processus. La réalisation d’une vente sur le
              plan légal et notarial n’est pas toujours évidente : beaucoup de paperasse,
              d’administratif et de pièces à fournir… nous sommes là pour vous aider à réunir les
              documents nécessaires, constituer votre dossier et faire en sorte de fluidifier les
              démarches jusqu'à la vente définitive.
            </Text>
          </Wrapper>
        </EvenBlock>
      </Blocks>
    </Container>
  )
}

export default Block1
